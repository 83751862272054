// extracted by mini-css-extract-plugin
export var crossPlatformBenefitsSection = "M_hC";
export var crossPlatformBusinessSection = "M_hF";
export var crossPlatformCasesSection = "M_hN";
export var crossPlatformExpertiseSection = "M_hK";
export var crossPlatformIndustriesSection = "M_hD";
export var crossPlatformPreferencesSection = "M_hL";
export var crossPlatformProcessSection = "M_hG";
export var crossPlatformProjLogoSection = "M_hM";
export var crossPlatformQuotesSection = "M_hP";
export var crossPlatformServicesSection = "M_hB";
export var crossPlatformTechStackSection = "M_hH";
export var crossPlatformTechStackSection__callToAction = "M_hJ";